import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { PATHS, RUN_TYPE, TUBULAR_TYPE, TYPE_HOLE_CASING, VARIABLES } from "src/app/utils/constants";
import * as OB from "src/app/utils/objecter";
import { ValidatorsEx } from "../validators-extend.class";
import { JobService } from "src/app/job/job.service";
import { PAGE_NAMES } from "src/app/shared/enums/enums";

@Injectable({
  providedIn: "root",
})
export class WarningService {
  
  private warningKeys = {
    requiredData: 'warnings.requiredData',
    casingWithoutOpenHole: 'warnings.casingWithoutOpenHole',
    casingTooLarge: 'warnings.casingTooLarge',
  }

  public intervalBha: string;

  public validationData = {
    openHoles: [],
    casings: [],
    wellbores: [],
    bhaRuns: [],
    thermals: [],
    jobId: '',
  }
  public message = {
    wellbore: '',
    openhole: '',
    bhaRun: '',
    thermal: '',
    pumps: '',
    solidsControlEquipment: '',
    pits: '',
    surfaceEquipment: '',
    intervalFluidSet: '',
    targetSet: '',
    wellHeader: ''
  }
  public loader = {
    wellbore$:  new Subject<boolean>(),
    bhaRun$: new Subject<boolean>(),
    intervalGeometry$: new Subject<boolean>(),
    intervalFluidSet$: new Subject<boolean>(),
    targetSet$: new Subject<boolean>()
  }

  public validatorResult = {
    rigProfile: {invalid: false},
    wellProfile: {invalid: false},
    intervals: {invalid: false}
  }

  public latitudeValidator: ValidateResult = { invalid: false };
  public longitudeValidator: ValidateResult = { invalid: false };

  constructor(
    public _job: JobService
  ) { }

  public validateThermals() {
    this.message.thermal = '';
    const requiredValidator = this.isThermalRequired(this.validationData.thermals);

    if(requiredValidator.invalid) {
      this.message.thermal = this.warningKeys.requiredData;
      return;
    }

    for (const thermalPoint of this.validationData.thermals) {
      const invalidValidator = this.isThermalInvalid(thermalPoint);
      if (invalidValidator.invalid) {
        this.message.thermal = this.warningKeys.requiredData;
        return;
      }
    }
  }

  public isThermalRequired(thermals) {
    const minTvd = Math.min(...thermals.map(x => x[VARIABLES.tvd]));
    const required = thermals.length < 2 || minTvd != DEFAULT_THERMAL_POINT.tvd;

    return {
      invalid : required,
      message: required ? "Geothermal Gradient entry required" : ""
    } as ValidateResult
  }

  public isThermalInvalid(thermalPoint): ValidateResult {
    let res: ValidateResult = {
      invalid: false
    };   
    const tvdArray = this.validationData.thermals.map(x => x[VARIABLES.tvd]);
    const maxTvd = Math.max(...tvdArray);
    const thermalTvd = thermalPoint[VARIABLES.tvd];

    if (thermalTvd == maxTvd && thermalTvd != DEFAULT_THERMAL_POINT.tvd) {
      const maxWellboreTvd = Math.max(...this.validationData.wellbores.map(x => x[VARIABLES.tvd][VARIABLES.value]));
      if (maxTvd < maxWellboreTvd) {
        res.invalid = true;
        res.message = "Geothermal Gradient entry required for DFG/CFG Analysis";
        return res;
      }
    }

    return res;
  }

  public isBhaInvalid(bhaRun): ValidateResult {
    if(!bhaRun) {
      return {
        invalid: false,
        message: ""
      } as ValidateResult;
    }

    const tubularComponents = OB.getValue(bhaRun, PATHS.bhaRun.tubularComponents, []);
    const runType = bhaRun.runType;
    let messages = new Set<string>();

    for(const tubular of tubularComponents) {
      const validator = this.isTubularInvalid(tubular, tubularComponents, runType);
      if(validator.invalid) {
        const tempMessages = validator.message.split("\n");
        for(const message of tempMessages) {
          messages.add(message);
        }
      }
    }

    const bitComponents = tubularComponents.filter(x=> OB.getValue(x, PATHS.tubularComponent.baraLogix) == TUBULAR_TYPE.BIT);
    if(bitComponents.length == 0 && runType != RUN_TYPE.OPEN_ENDED) {
      messages.add("Bit to be placed at bottom of tubular component list");
    }

    const mess = Array.from(messages);
    return {
      invalid: mess.length > 0 ? true: false,
      message: mess.join("\n")
    } as ValidateResult;
  }
  public isSolidInvalid(solid): ValidateResult {
    const requiredValidator = this.isIntervalSolidsControlRequired(solid);
    let messages = new Set<string>();
    if(requiredValidator.invalid) {
      messages.add(requiredValidator.message);
    }
    const mess = Array.from(messages);
    return {
      invalid: mess.length > 0 ? true: false,
      message: mess.join("\n")
    } as ValidateResult;
  }
  
  public isTubularInvalid(tubularComponent, tubularComponents, runType): ValidateResult {
    const baraLogix = OB.getObj(tubularComponent, PATHS.tubularComponent.baraLogix);

    if (baraLogix === TUBULAR_TYPE.DRILL_PIPE) {
      const tjId = OB.getObj(tubularComponent, PATHS.tubularComponent.connectionId);
      const tjOd = OB.getObj(tubularComponent, PATHS.tubularComponent.connectionOd);
      const tjLen = OB.getObj(tubularComponent, PATHS.tubularComponent.connectionLen);
      const id = OB.getObj(tubularComponent, PATHS.tubularComponent.id);
      const od = OB.getObj(tubularComponent, PATHS.tubularComponent.od);
      const lenJoint = OB.getObj(tubularComponent, PATHS.tubularComponent.lenJointAv);

      if (!tjId || !tjOd || !tjLen || !lenJoint || (tjId === id && tjOd === od && tjLen === DEFAULT_CONNECTION_LENGTH && lenJoint === DEFAULT_JOINT_LENGTH)) {
        return {
          invalid: true,
          message: "Drill Pipe TJ info required"
        } as ValidateResult;
      }
    }

    if (baraLogix === TUBULAR_TYPE.BIT && runType != RUN_TYPE.OPEN_ENDED) {
      const areaNozzleFlow = OB.getObj(tubularComponent, PATHS.tubularComponent.areaNozzleFlow);
      const nozzle = OB.getObj(tubularComponent, PATHS.tubularComponent.nozzle);
      let message = [];
      if (areaNozzleFlow == 0 && nozzle.length == 0) {
        message.push("Bit TFA/Jets required");
      }

      const maxSeq = Math.max(...tubularComponents.map(x => OB.getObj(x, PATHS.tubularComponent.sequence)));
      const seq = OB.getValue(tubularComponent, PATHS.tubularComponent.sequence);
      const maxBitComponent = this.getMaxBitComponent(tubularComponents);
      const maxBitSeq = OB.getValue(maxBitComponent, PATHS.tubularComponent.sequence);
      if(seq == maxBitSeq && seq < maxSeq) {
        message.push("Bit to be placed at bottom of tubular component list");
      }

      if(message.length > 0) {
        return {
          invalid: true,
          message: message.join("\n")
        } as ValidateResult; 
      }
    }

    return  {
      invalid: false
    } as ValidateResult;
  }

  private getMaxBitComponent(tubularComponents) {
    let result = null;
    let maxSeq = -1;
    for(const item of tubularComponents) {
      const type = OB.getObj(item, PATHS.tubularComponent.baraLogix);
      const seq = OB.getValue(item, PATHS.tubularComponent.sequence);
      if(type == TUBULAR_TYPE.BIT && seq > maxSeq) {
        maxSeq = seq;
        result = item;
      }
    }

    return result;
  }

  public updateIntervalBhaStatus(bhaRuns) {
    this.intervalBha = "";
    for (const bhaRun of bhaRuns) {
      const validator = this.isBhaInvalid(bhaRun);
      if (validator.invalid) {
        this.intervalBha = this.warningKeys.requiredData;
        return;
      }
    }
  }
  public updateSolidControlStatus(solidControl) {
    this.message.solidsControlEquipment = "";
    const validator=this.isIntervalSolidsControlRequired(solidControl)
    if (validator.invalid) {
      this.message.solidsControlEquipment = this.warningKeys.requiredData;
      return;
    }
  }
  public isIntervalSolidsControlRequired(solidsControl) {
    const invalidLength = solidsControl?.length == 0 || solidsControl == undefined;
    return {
      invalid: invalidLength,
      message: invalidLength ? "Minimum one Baroid Separation Solution must be selected" : ""
    } as ValidateResult;
  }

  public bhaRunLoaded() {
    return new Promise<boolean>((resolve, reject) => {
      this.loader.bhaRun$.subscribe(loaded => {
        if(loaded) {
          resolve(true);
        }
      })
    });
  }

  public wellboreLoaded() {
    return new Promise<boolean>((resolve, reject) => {
      this.loader.wellbore$.subscribe(loaded => {
        if(loaded) {
          resolve(true);
        }
      })
    });
  }

  public intervalfluidSetLoaded() {
    return new Promise<boolean>((resolve, reject) => {
      this.loader.intervalFluidSet$.subscribe(loaded => {
        if(loaded) {
          resolve(true);
        }
      })
    });
  }

  public targetSetLoaded() {
    return new Promise<boolean>((resolve, reject) => {
      this.loader.targetSet$.subscribe(loaded => {
        if(loaded) {
          resolve(true);
        }
      })
    });
  }

  public valiateBhaRuns() {
    this.message.bhaRun = '';

    for(const bhaRun of this.validationData.bhaRuns) {
      if(this.isBhaInvalid(bhaRun).invalid) {
        this.message.bhaRun = this.warningKeys.requiredData;
        return "Required data";
      }
    }
  }

  public validateOpenHoles() {
    this.message.openhole = '';

    if (this.validationData.openHoles.length == 0) {
      this.message.openhole = this.warningKeys.requiredData;
      return "Required data";
    }
    for(const openHole of this.validationData.openHoles) {
      if(this.isOpenHoleInvalid(openHole).invalid) {
        this.message.openhole = this.warningKeys.requiredData;
        return "Required data";
      }
    }

    return null;
  }

  public validateWellbores() {
    this.message.wellbore = '';

    for(const wellbore of this.validationData.wellbores) {
      if(this.isWellboreInvalid(wellbore).invalid) {
        this.message.wellbore = this.warningKeys.requiredData;
        return "Required data";
      }
    }

    return null;
  }

  public isWellboreInvalid(wellbore): ValidateResult {
    const geometrySections = OB.getValue(wellbore, PATHS.wellbore.wellboreGeometrySections, []);
    let messages = new Set<string>();
    this.validationData.openHoles = [];
    this.validationData.casings = [];

    if (geometrySections.length > 0) {
      this.validationData.openHoles = geometrySections.filter(x => OB.getObj(x, PATHS.geometrySection.typeHoleCasing) === TYPE_HOLE_CASING.OPEN_HOLE);
      this.validationData.openHoles = this.validationData.openHoles.sort((a, b) =>OB.getValue(a, PATHS.geometrySection.mdTop) - OB.getValue(b, PATHS.geometrySection.mdTop));
      this.validationData.casings = geometrySections.filter(x => {
        const typeHoleCasing = OB.getObj(x, PATHS.geometrySection.typeHoleCasing);
        return typeHoleCasing === TYPE_HOLE_CASING.CASING || typeHoleCasing === TYPE_HOLE_CASING.LINER
      });
    }

    if(this.validationData.openHoles.length == 0) {
      messages.add("Open Hole required");
    }

    for(const openHole of this.validationData.openHoles) {
      const validator = this.isOpenHoleInvalid(openHole);
      if(validator.invalid) {
        messages.add(validator.message);
      }
    }

    const mess = Array.from(messages);
    return {
      invalid: mess.length > 0 ? true: false,
      message: mess.join("\n")
    } as ValidateResult;
  }

  public isOpenHoleInvalid(openHole): ValidateResult {
    const topMd = OB.getValue(openHole, PATHS.geometrySection.mdTop);
    const endMd = OB.getValue(openHole, PATHS.geometrySection.mdBase);
    const minOpenHole = this.getMinGeometries(this.validationData.openHoles);
    const maxOpenHole = this.getMaxGeometries(this.validationData.openHoles);
    const minCasing = this.getMinGeometries(this.validationData.casings);
    const maxCasing = this.getMaxGeometries(this.validationData.casings);
    var msg=[];

    const indexOpenHole=this.validationData.openHoles.findIndex(value => OB.getValue(value, PATHS.geometrySection.mdTop) == topMd && OB.getValue(value, PATHS.geometrySection.mdBase) == endMd );
    if(indexOpenHole != 0 && indexOpenHole != -1){
      const endMdPreviousOpenHole= OB.getValue(this.validationData.openHoles[indexOpenHole-1],PATHS.geometrySection.mdBase);
      if(topMd != endMdPreviousOpenHole){
        msg.push("This open hole top depth is different from the previous open hole. Please verify your inputs")
      }
    }
    
    if((topMd == minOpenHole && topMd > minCasing) || (endMd == maxOpenHole && endMd < maxCasing)) {
      msg.push("Open Hole sections behind all casing required");
      return {
        invalid: true,
        message: msg.join("\n")
      } as ValidateResult;
    }
    for(const casing of this.validationData.casings) {
      if(ValidatorsEx.isOverlap([casing], topMd, endMd, PATHS.geometrySection.mdTop, PATHS.geometrySection.mdBase)) {
        const openHoleId = OB.getObj(openHole, PATHS.geometrySection.idSection);
        const casingOd = OB.getObj(casing, PATHS.geometrySection.odSection);
        if (openHoleId < casingOd) {
          msg.push("Casing too large for Open Hole section");
          return {
            invalid: true,
            message: msg.join("\n")
          } as ValidateResult;
        }
      }
    }

    return  {
      invalid: msg.length > 0  ? true:false,
      message: msg.join("\n")
    } as ValidateResult;
  }

  public isIntervalInvalid(interval): ValidateResult {
    const bhaRuns = OB.getValue(interval, VARIABLES.bhaRunsRef, []);   
    const fluidSets = OB.getValue(interval, VARIABLES.fluidSetIds, []);
    const solids=OB.getValue(interval,VARIABLES.solidControlsEquipamentsIds,[]);
    const targetSets = OB.getValue(interval, VARIABLES.targetSetsRef, []);
    this.validationData.openHoles = OB.getValue(interval, VARIABLES.openHolesRef, []);
    this.validationData.casings = OB.getValue(interval, VARIABLES.casingLinersRef, []);
    let messages = [];

    const openHoleValidator = this.isIntervalOpenHoleInvalid(this.validationData.openHoles);
    if(openHoleValidator.invalid) {
      messages = messages.concat(openHoleValidator.message.split("\n"));
    }
    if(interval.jobType == ''){
      messages= messages.concat("Interval should have job type assigned");
    }
    if(!(this._job.isSeparationSolutionJob$.value && this._job.jobObj$.value.jobTypes.length==1)) {
      const fluidSetValidator = this.isIntervalFluidSetInvalid(fluidSets);
      if(fluidSetValidator.invalid) {
        messages = messages.concat(fluidSetValidator.message.split("\n"));
      }

      const targetSetValidator = this.isTargetSetInvalid(targetSets);
      if(targetSetValidator.invalid) {
        messages = messages.concat(targetSetValidator.message.split("\n"));
      }
    }
    if(this._job.isSeparationSolutionJob$.value){
      const solidValidator=this.isSolidInvalid(solids);
        if(solidValidator.invalid){
          messages=messages.concat(solidValidator.message.split("\n"));
        }
    }

    for(const bha of bhaRuns) {
      const bhaValidator = this.isBhaInvalid(bha);
      if(bhaValidator.invalid) {
        messages = messages.concat(bhaValidator.message.split("\n"));
      }
    }

    const mess = OB.groupStringArr(messages);
    return {
      invalid: mess.length > 0 ? true: false,
      message: mess.join("\n")
    } as ValidateResult;
  }

  public isIntervalRequired(intervals): ValidateResult {
    let intervalRequired = true;

    for (const interval of intervals) {
      const validator = this.isIntervalInvalid(interval);
      if (!validator.invalid) {
        intervalRequired = false;
        break;
      }
    }

    return {
      invalid: intervalRequired,
      message: intervalRequired ? "Minimum one viable Interval is required" : ""
    } as ValidateResult;
  }

  private getMinGeometries(geometries): number {
    return Math.min(... geometries.map(x => OB.getObj(x, PATHS.geometrySection.mdTop)));
  }

  private getMaxGeometries(geometries): number {
    return Math.max(... geometries.map(x => OB.getObj(x, PATHS.geometrySection.mdBase)));
  }

  public validateSurfaceEquipment(surfaceEquipment) {
    this.message.surfaceEquipment = '';
    const surfaceValidator = this.isSurfaceEquipmentInvalid(surfaceEquipment);
    
    if (surfaceValidator.standPipe.invalid
      || surfaceValidator.drillingHose.invalid
      || surfaceValidator.swivel.invalid
      || surfaceValidator.kelly.invalid) {
      this.message.surfaceEquipment = this.warningKeys.requiredData;
      return this.warningKeys.requiredData;
    }

    return null;
  }

  public validateWellHeader(wellHeader) {
    let messages = new Set<string>();
    this.message.wellHeader = '';
    this.latitudeValidator = this.isLatitudeInvalid(wellHeader);
    this.longitudeValidator = this.isLongitudeInvalid(wellHeader);

    if(this.latitudeValidator.invalid) {
      messages.add(this.latitudeValidator.message);
      this.message.wellHeader = this.warningKeys.requiredData;
    }

    if(this.longitudeValidator.invalid) {
      messages.add(this.longitudeValidator.message)
      this.message.wellHeader = this.warningKeys.requiredData;
    }

    const mess = Array.from(messages);
    return {
      invalid: mess.length > 0,
      message: mess.join("\n")
    } as ValidateResult;
  }
  
  public isLatitudeInvalid(wellHeader) {
    let isInvalid = false;

    if(!OB.getObj(wellHeader, PATHS.wellHeader.latitude)) {
      isInvalid = true;
    }

    return {
      invalid: isInvalid,
      message: isInvalid ? "Latitude needs to be updated from default value" : ""
    } as ValidateResult;
  }

  public isLongitudeInvalid(wellHeader) {
    let isInvalid = false;

    if(!OB.getObj(wellHeader, PATHS.wellHeader.longitude)) {
      isInvalid = true;
    }

    return {
      invalid: isInvalid,
      message: isInvalid ? "Longitude needs to be updated from default value" : ""
    } as ValidateResult;
  }

  public isSurfaceEquipmentInvalid(surfaceEquipment) {    
    let result = {
      standPipe: {
        invalid: false
      } as ValidateResult,
      drillingHose: {
        invalid: false
      } as ValidateResult,
      swivel: {
        invalid: false
      } as ValidateResult,
      kelly: {
        invalid: false
      } as ValidateResult
    };

    if(this._job.isSeparationSolutionJob$.value)
      return result;

    if (!OB.getObj(surfaceEquipment, PATHS.rig.surfaceEquipment.idStandpipe) || !OB.getObj(surfaceEquipment, PATHS.rig.surfaceEquipment.lenStandpipe)) {
      result.standPipe = {
        invalid: true,
        message: "Stand Pipe data entry required"
      }
    }

    if (!OB.getObj(surfaceEquipment, PATHS.rig.surfaceEquipment.idHose) || !OB.getObj(surfaceEquipment, PATHS.rig.surfaceEquipment.lenHose)) {
      result.drillingHose = {
        invalid: true,
        message: "Drilling Hose data entry required"
      }
    }

    if (!OB.getObj(surfaceEquipment, PATHS.rig.surfaceEquipment.idSwivel) || !OB.getObj(surfaceEquipment, PATHS.rig.surfaceEquipment.lenSwivel)) {
      result.swivel = {
        invalid: true,
        message: "Swivel data entry required"
      }
    }

    if (!OB.getObj(surfaceEquipment, PATHS.rig.surfaceEquipment.idKelly) || !OB.getObj(surfaceEquipment, PATHS.rig.surfaceEquipment.lenKelly)) {
      result.kelly = {
        invalid: true,
        message: "Kelly data entry required"
      }
    }

    return result;
  }

  public validatePumps(pumps) {
    this.message.pumps = "";
    const validator = this.isPumpsRequired(pumps);

    if (validator.invalid) {
      this.message.pumps = this.warningKeys.requiredData;
    }
  }

  public isPumpsRequired(pumps) {
    const invalidLength = pumps.length == 0 && !this._job.isSeparationSolutionJob$.value;
    return {
      invalid: invalidLength,
      message: invalidLength ? "Minimum of one pump required" : ""
    } as ValidateResult;
  }

  public validatePits(pits) {
    this.message.pits = "";
    const validator = this.isPitsRequired(pits);

    if (validator.invalid) {
      this.message.pits = this.warningKeys.requiredData;
    }
  }

  public isPitsRequired(pits) {
    const invalidLength = pits.length == 0;
    return {
      invalid: invalidLength,
      message: invalidLength ? "Minimum of one pit required" : ""
    } as ValidateResult;
  }

  public validateSolidsControlEquipments(solidsControlEquipments) {
    this.message.solidsControlEquipment = "";
    const validator = this.isSolidsControlEquipmentsRequired(solidsControlEquipments);

    if (validator.invalid) {
      this.message.solidsControlEquipment = this.warningKeys.requiredData;
    }
  }

  public isSolidsControlEquipmentsRequired(solidsControlEquipments) {
    const invalidLength = solidsControlEquipments.length == 0;
    return {
      invalid: invalidLength,
      message: invalidLength ? "Minimum of one SCE item required" : ""
    } as ValidateResult;
  }
  
  public isIntervalFluidSetInvalid(fluidSets) {
    const requiredValidator = this.isIntervalFluidSetsRequired(fluidSets);
    let messages = new Set<string>();

    if(requiredValidator.invalid) {
      messages = messages.add(requiredValidator.message);
    }

    const mess = Array.from(messages);
    return {
      invalid: mess.length > 0 ? true: false,
      message: mess.join("\n")
    } as ValidateResult;
  }

  public validateIntervalFluidSets(fluidSets) {
    this.message.intervalFluidSet = '';
    const validator = this.isIntervalFluidSetsRequired(fluidSets);

    if (validator.invalid) {
      this.message.intervalFluidSet = this.warningKeys.requiredData;
    }
  }

  public isIntervalFluidSetsRequired(fluidSets) {
    const invalidLength = fluidSets?.length == 0;
    return {
      invalid: invalidLength,
      message: invalidLength ? "Minimum one Fluid Set within Interval" : ""
    } as ValidateResult;
  }

  public isTargetSetInvalid(targetSets) {
    const requiredValidator = this.isTargetSetsRequired(targetSets);
    let messages = new Set<string>();

    if(requiredValidator.invalid) {
      messages = messages.add(requiredValidator.message);
    }

    const mess = Array.from(messages);
    return {
      invalid: mess.length > 0 ? true: false,
      message: mess.join("\n")
    } as ValidateResult;
  }

  public validateTargetSets(targetSets) {
    this.message.targetSet = '';
    const validator = this.isTargetSetsRequired(targetSets);

    if (validator.invalid) {
      this.message.targetSet = this.warningKeys.requiredData;
    }
  }

  public isTargetSetsRequired(targetSets) {
    const invalidLength = targetSets.length == 0;
    return {
      invalid: invalidLength,
      message: invalidLength ? "Minimum one Target Set within Interval" : ""
    } as ValidateResult;
  }

  public isIntervalOpenHoleInvalid(openHoles) {
    const requiredValidator = this.isIntervalOpenHoleRequired(openHoles);
    let messages = new Set<string>();

    if(requiredValidator.invalid) {
      messages = messages.add(requiredValidator.message);
    }

    for(const openHole of openHoles) {
      const invalidValidator = this.isOpenHoleInvalid(openHole);
      if(invalidValidator.invalid) {
        messages.add(invalidValidator.message);
      }
    }

    const mess = Array.from(messages);
    return {
      invalid: mess.length > 0 ? true: false,
      message: mess.join("\n")
    } as ValidateResult;
  }

  public isIntervalOpenHoleRequired(openHoles): ValidateResult {
    const invalidLength = openHoles.length == 0;
    return {
      invalid: invalidLength,
      message: invalidLength ? "Minimum one Open Hole section within Interval" : ""
    } as ValidateResult;
  }

  public validateRigProfile(jobPacket): ValidateResult {
    const surfaceEquipment = OB.getObj(jobPacket, PATHS.jobPacket.surfaceEquipment);

    const solidsControlEquipment = OB.getObj(jobPacket, PATHS.jobPacket.solidsControlEquipment) ? [{}] : [];    
    const mudPumps = OB.getValue(jobPacket, PATHS.jobPacket.mudPumps, []);
    const pits = OB.getValue(jobPacket, PATHS.jobPacket.pits, []);

    const surfaceEquipmentValidator = this.isSurfaceEquipmentInvalid(surfaceEquipment);
    const solidsControlEquipmentValidator = this.isSolidsControlEquipmentsRequired(solidsControlEquipment);
    const mudPumpsValidator = this.isPumpsRequired(mudPumps);
    const pitsValidator = this.isPitsRequired(pits);

    let messages = new Set<string>();

    if(surfaceEquipmentValidator.standPipe.invalid) {
      messages.add(surfaceEquipmentValidator.standPipe.message);
    }

    if(surfaceEquipmentValidator.drillingHose.invalid) {
      messages.add(surfaceEquipmentValidator.drillingHose.message);
    }

    if(surfaceEquipmentValidator.swivel.invalid) {
      messages.add(surfaceEquipmentValidator.swivel.message);
    }

    if(surfaceEquipmentValidator.kelly.invalid) {
      messages.add(surfaceEquipmentValidator.kelly.message);
    }

    if(solidsControlEquipmentValidator.invalid) {
      messages.add(solidsControlEquipmentValidator.message);
    }

    if(mudPumpsValidator.invalid) {
      messages.add(mudPumpsValidator.message);
    }

    if(pitsValidator.invalid) {
      messages.add(pitsValidator.message);
    }

    const mess = Array.from(messages);
    return {
      invalid: mess.length > 0 ? true : false,
      message: mess.join("\n")
    } as ValidateResult;
  }

  public validateWellProfile(jobPacket): ValidateResult {
    const well = OB.getObj(jobPacket, VARIABLES.well);
    const wellbores = OB.getValue(jobPacket, PATHS.jobPacket.wellbores, []);
    const bhaRuns = OB.getValue(jobPacket, PATHS.jobPacket.bhaRuns, []);

    const thermalGradients = OB.getValue(jobPacket, PATHS.jobPacket.thermalGradients, []).map(el => {
      return {
        tvd: Number(el[0]),
        temperture: Number(el[1])
      }
    });

    let messages = new Set<string>();

    const wellHeaderValidator = this.validateWellHeader(well);
    if (wellHeaderValidator.invalid) {
      messages.add(wellHeaderValidator.message);
    }

    for(const wellbore of wellbores) {
      const wellboreValidator = this.isWellboreInvalid(wellbore);
      if(wellboreValidator.invalid) {
        for(const mess of wellboreValidator.message.split("\n")) {
          messages.add(mess);
        }       
      }
    }
    
    if(this._job.isSeparationSolutionJob$.value){
      let mess = Array.from(messages);
      return {
        invalid: mess.length > 0 ? true : false,
        message: mess.join("\n")
      } as ValidateResult;
    }

    for(const bhaRun of bhaRuns) {
      const bhaRunValidator = this.isBhaInvalid(bhaRun);
      if(bhaRunValidator.invalid) {
        for(const mess of bhaRunValidator.message.split("\n")) {
          messages.add(mess);
        }  
      }
    }

    const requiredThermalValidator = this.isThermalRequired(thermalGradients);

    if(requiredThermalValidator.invalid) {
      messages.add(requiredThermalValidator.message);
    }

    for (const thermalPoint of thermalGradients) {
      const invalidThermalValidator = this.isThermalInvalid(thermalPoint);
      if (invalidThermalValidator.invalid) {
        messages.add(invalidThermalValidator.message);
      }
    }
    
    const mess = Array.from(messages);
    return {
      invalid: mess.length > 0 ? true : false,
      message: mess.join("\n")
    } as ValidateResult;
  }

  public validateIntervals(jobPacket): ValidateResult {
    const intervals = OB.getValue(jobPacket, PATHS.jobPacket.intervals, []); 
    const wellbores = OB.getValue(jobPacket, PATHS.jobPacket.wellbores, []);
    const bhaRuns = OB.getValue(jobPacket, PATHS.jobPacket.bhaRuns, []);
    let messages = new Set<string>();
    let invalidCounter = 0;

    for(let interval of intervals) {
      const openHoleIds = OB.getValue(interval, VARIABLES.openHoleIds, []);
      const casingLinerIds = OB.getValue(interval, VARIABLES.casingLinerIds, []);
      const bhaRunIds = OB.getValue(interval, VARIABLES.bhaRunIds, []);
      const wellbore = wellbores.find(x => x[VARIABLES.wellboreId] === interval[VARIABLES.wellboreId]);
      const geometrySections = wellbore ? OB.getObj(wellbore, PATHS.wellbore.wellboreGeometrySections): [];

      const openHolesRef = openHoleIds.map(openHoleId => geometrySections.find(x => openHoleId === OB.getObj(x, PATHS.geometrySection.wellboreGeometrySectionId)));
      const casingLinersRef = casingLinerIds.map(casingLinerId => geometrySections.find(x => casingLinerId === OB.getObj(x, PATHS.geometrySection.wellboreGeometrySectionId)));
      const bhaRunsRef = bhaRunIds.map(bhaRunId => bhaRuns.find(x => bhaRunId === OB.getObj(x, PATHS.bhaRun.bhaRunId)));

      const { targetSets, ...others } = interval;
      interval = { [VARIABLES.targetSetsRef]: targetSets, ...others };
      OB.updateObj(interval, VARIABLES.openHolesRef, openHolesRef);
      OB.updateObj(interval, VARIABLES.casingLinersRef, casingLinersRef);
      OB.updateObj(interval, VARIABLES.bhaRunsRef, bhaRunsRef);

      const invalidValidator = this.isIntervalInvalid(interval);
      if (invalidValidator.invalid) {  
        invalidCounter++;     
        for (const mess of invalidValidator.message.split("\n")) {
          messages.add(mess);
        }
      }
    }

    if(invalidCounter == intervals.length) {
      messages.add("Minimum one viable Interval is required");
    }

    const mess = Array.from(messages);
    return {
      invalid: mess.length > 0 ? true : false,
      message: mess.join("\n")
    } as ValidateResult;
  }

  public validateJob(page?: string) {
    this._job.getJobPacket(this.validationData.jobId).then(res => {
      switch (page) {
        case PAGE_NAMES.RigProfile:
          this.validatorResult.rigProfile = this.validateRigProfile(res);
          break;
        case PAGE_NAMES.WellProfile:
          this.validatorResult.wellProfile = this.validateWellProfile(res);
          break;
        case PAGE_NAMES.Intervals:
          this.validatorResult.intervals = this.validateIntervals(res);
          break;
        default:
          this.validatorResult.rigProfile = this.validateRigProfile(res);
          this.validatorResult.wellProfile = this.validateWellProfile(res);
          this.validatorResult.intervals = this.validateIntervals(res);
      }      
    })   
  }
}

export const DEFAULT_CONNECTION_LENGTH = 2;
export const DEFAULT_JOINT_LENGTH = 30;
export const DEFAULT_THERMAL_POINT = {
  tvd: 0,
  temperture: 50
};
export interface ValidateResult {
  invalid: boolean;
  message?: string;
}
