import { DateTimeService } from './../core/services/date-time.services';
import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { UtilitiesService } from '../core/services/utilitiesservice.service';
import { JobService } from '../job/job.service';
import { JobDashboard } from './../shared/models/jobs.model';
import * as LS from 'src/app/utils/localstorage';;
import {SORT_TYPE, TOASTER_TYPE } from '../shared/enums/enums';
import { DialogInput, DialogResult } from '../shared/components/dialog/dialog.component';
import { DialogModalService } from '../core/services/dialogModal.service';
import { environment } from 'src/environments/environment';
import { ApiFilterModel } from '../shared/models/job-dashboard-filter-data.model';
import * as OB from 'src/app/utils/objecter';
import { SettingsService } from '../core/services/settings.service';
import { finalize, map } from 'rxjs/operators';
import { ComponentNotification, ListViewFilterComponent } from './list-view-filter/list-view-filter.component';
import {SAVE_DATA} from '../utils/constants';

export enum JOB_CHOICES {
  NEW = "new",
  CLONE = "clone"
};

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  public listJobs = [];
  public recentJobs = [];
  public jobCount: number;
  public componentDestroyed$: Subject<boolean> = new Subject();
  public pageLoaded = false;
  public pageSize = 25;
  public isShowAll = true;
  public jobsQuery: ApiFilterModel = {
    filterColumns: [],
    sortColumns: [
      {
        id: "jobName",
        value: SORT_TYPE.ASC.toLowerCase()
      }
    ],
    pageIndex: 1,
    pageSize: 10
  };
  public dialogInput: DialogInput = {
    data: {
      body: ""
    },
    buttons: {
      cancel: false,
      ok: true
    }
  };
  public createJobDialogInput: DialogInput = {
    data: {
      header: "New job",
      options: [
        {
          label: "Create new Job and new Well",
          value: JOB_CHOICES.NEW
        },
        {
          label: "Copy an existing Job",
          value: JOB_CHOICES.CLONE
        }
      ]
    },
    buttons: {
      cancel: true,
      ok: false,
      next: true
    }
  };
  public headers = [
    { title: 'job.name', uom: false, key: "jobName", type: 'text', maxColumnDisplayLength: 20 },
    { title: 'job.jobNumber', uom: false, key: "jobNumber", type: 'text', maxColumnDisplayLength: 20 },
    { title: 'job.jobType', uom: false, key: "jobTypes", type: 'array' },
    { title: 'rig.name', uom: false, key: "rigName", type: 'text', maxColumnDisplayLength: 20 },
    { title: 'well.name', uom: false, key: "wellName", type: 'text', maxColumnDisplayLength: 20 },
    { title: 'spudDate', uom: false, key: "spudDate", type: 'date' },
    { title: 'job.createdBy', uom: false, key: "jobOwner", type: 'text', maxColumnDisplayLength: 20 },
    { title: 'job.status', uom: false, key: "jobStatus", type: 'text', maxColumnDisplayLength: 20 },
    { title: 'region', uom: false, key: "region", type: 'text', maxColumnDisplayLength: 20 },
    { title: 'country', uom: false, key: "country", type: 'text', maxColumnDisplayLength: 20 },
    { title: '', uom: false, icon: true, class: 'filter-color-orange ic-edit-icon edit-btn icon-collumn', key: "editIcon" }
  ];
  public showInfo: boolean = false;
  public jobPacketInfo: any;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public datePipe: DatePipe,
    public _utilities: UtilitiesService,
    public _dateTime: DateTimeService,
    public _job: JobService,
    public _dialogModal: DialogModalService,
    public _settings: SettingsService
  ) {
  }

  ngOnInit() {
    this.switchView(true);
  }

  ngAfterViewInit() {
    setTimeout(f=>{this.notifyJobAuthentication()}, 0);
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  private notifyJobAuthentication() {
    if(this._utilities.jobAuthenticationInfo){
      this.dialogInput.data.body = this._utilities.jobAuthenticationInfo?.message;
      this._dialogModal.showInfo(this.dialogInput);
      this._utilities.jobAuthenticationInfo = null;
    }
  }

  openAddJobDashboardModal() {
    if(environment.local) {
      this.router.navigate(['/jobs/newjob/jobprofile']);
      return;
    }
    
    this._dialogModal.showConfirmation(this.createJobDialogInput).subscribe(res => {
      if (res.action && res.action === DialogResult.Ok) {
        switch(res.value) {
          case JOB_CHOICES.NEW:
            this.router.navigate(['/jobs/newjob/jobprofile']);
            break;
          case JOB_CHOICES.CLONE:
            this.router.navigate(['/jobs/newjob/clone']);
        }
      }
    });
  }

  jobsApi = (filter: any): Observable<any> => {
    let api = this._job.searchSortPage(filter);
    if(!this.isShowAll) {
      api = this._job.getLastVisitedJobs();
    }

    this._utilities.showSpinner(true);
    return api.pipe(
      map((res: any) => {
        this.jobCount = res.totalCount;
        return res;
      }),
      finalize(() => {
        this._utilities.showSpinner(false);
      })
    );
    
  }

  public onModalClose() {
    this.showInfo = false;
  }

  jobDetails(event) {
    const header = event.header;
    const row = event.row;
    if(header.key = 'editIcon') {
      this.router.navigate([`/job/${row.jobId}/jobprofile`]);
    }
  }

  public switchView(initial = false) {
    if(initial) {
      const cached = LS.getLocalStorage(LS.LOCAL_STORAGE_KEY.User.ShowAllJobStatus);
      this.isShowAll = cached ? cached: false;
    }

    LS.setLocalStorage(LS.LOCAL_STORAGE_KEY.User.ShowAllJobStatus, this.isShowAll);
    this.jobsQuery = {
      filterColumns: [],
      sortColumns: [
        {
          id: "jobName",
          value: SORT_TYPE.ASC.toLowerCase()
        }
      ],
      pageIndex: 1,
      pageSize: 10
    };
  }
  public importJob(event){
    const input = event.target as HTMLInputElement;

    if (input.files && input.files.length > 0) {
      const file = input.files[0];

      if (file.name.endsWith('.json')) {
        file.text().then((content) => {
          var json = {
            encrypt : content
          }
          this._job.decryptJobPacket(json);
        })
      } else {
         this._utilities.showToastrWithTimer(TOASTER_TYPE.ERROR, "Something went wrong. Please try again later or with another file", SAVE_DATA.Toastr_Fail);
        
      }
      input.value = '';
    }
  }
  
}
